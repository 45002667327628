<template>
    <div>
        <b-row class="content-header">
            <!-- Content Left -->
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2 class="float-left mb-0">Downloader</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="8">
                <b-card>
                    <b-card-title
                        >Premium Link Generator
                        <feather-icon
                            icon="InfoIcon"
                            size="25"
                            class="d-lg-none text-primary"
                            @click="showLimits = true"
                        ></feather-icon
                    ></b-card-title>
                    <b-card-text
                        >Paste your link to the box to generate premium
                        link.</b-card-text
                    >
                    <b-overlay
                        :show="!this.$store.getters.getUser.has_premium"
                        rounded="sm"
                    >
                        <validation-observer
                            ref="generatorForm"
                            #default="{ invalid }"
                        >
                            <b-form
                                class="mt-2"
                                @submit.prevent="onGenerate"
                                v-if="show"
                            >
                                <b-form-group>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Links"
                                        rules="required|max_links"
                                    >
                                        <b-form-textarea
                                            id="urllist"
                                            rows="6"
                                            v-model="urllist"
                                            placeholder="Your links here (1 link per row recommended)"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                        />
                                        <small class="text-danger">{{
                                            errors[0]
                                        }}</small>
                                    </validation-provider>
                                </b-form-group>
                                <b-form-input
                                    id="password"
                                    v-model="password"
                                    placeholder="Password (Optional)"
                                    class="mt-2"
                                />
                                <div class="d-flex mt-2">
                                    <b-button
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="gradient-primary"
                                        :disabled="invalid"
                                        class="mr-2"
                                        type="submit"
                                    >
                                        Generate
                                    </b-button>
                                    <b-button
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="outline-danger"
                                        class="mr-2"
                                        @click.prevent="resetForm"
                                    >
                                        Reset
                                    </b-button>
                                </div>
                            </b-form>
                        </validation-observer>

                        <template #overlay>
                            <h3>Please upgrade premium to use this service.</h3>
                        </template>
                    </b-overlay>
                </b-card>
                <b-card
                    v-for="item in result"
                    :key="item.id"
                    :bg-variant="item.variant"
                    ><b-card-text class="text-white">
                        <b-spinner
                            small
                            class="mr-1"
                            v-if="item.variant == 'secondary'" /><feather-icon
                            size="16"
                            icon="DownloadIcon"
                            class="mr-50"
                            v-if="item.variant == 'primary'" /><feather-icon
                            size="16"
                            icon="AlertOctagonIcon"
                            class="mr-50"
                            v-if="item.variant == 'danger'" /><span
                            v-html="item.message"
                        ></span></b-card-text
                ></b-card>
            </b-col>
            <b-col class="right-side" :class="{ active: showLimits }">
                <b-card class="card-transaction">
                    <b-card-title
                        >Limits
                        <feather-icon
                            icon="XSquareIcon"
                            size="25"
                            class="d-lg-none text-danger"
                            @click="showLimits = false"
                        ></feather-icon>
                    </b-card-title>
                    <app-collapse type="margin">
                        <app-collapse-item title="Usage">
                            <span>Today: </span
                            ><span class="float-right"
                                >{{ formatBytes(valueProgess) }}/{{
                                    formatBytes(maxProgess)
                                }}</span
                            >
                            <b-progress
                                :max="maxProgess"
                                :value="valueProgess"
                                class="mt-1"
                            />
                        </app-collapse-item>
                        <app-collapse-item title="Files / day">
                            <div
                                class="transaction-item"
                                v-for="item in files_limit"
                                :key="item.host"
                            >
                                <b-media no-body>
                                    <b-img
                                        :src="iconHost(item.host)"
                                        class="mr-1"
                                        height="16"
                                    />
                                    <span class="align-self-center my-auto">
                                        {{ item.host }}
                                    </span>
                                </b-media>

                                <div class="d-flex align-items-center">
                                    <span class="text-body-heading mr-1"
                                        >{{ item.files.used }} /
                                        {{ item.files.total }}</span
                                    >
                                </div>
                            </div>
                        </app-collapse-item>
                        <app-collapse-item title="GB / day">
                            <div
                                class="transaction-item"
                                v-for="item in bw_limit"
                                :key="item.host"
                            >
                                <b-media no-body>
                                    <b-img
                                        :src="iconHost(item.host)"
                                        class="mr-1"
                                        height="16"
                                    />
                                    <span class="align-self-center my-auto">
                                        {{ item.host }}
                                    </span>
                                </b-media>

                                <div class="d-flex align-items-center">
                                    <span class="text-body-heading mr-1"
                                        >{{ formatBytes(item.bandwidth.used) }}
                                        /
                                        {{
                                            formatBytes(item.bandwidth.total)
                                        }}</span
                                    >
                                </div>
                            </div>
                        </app-collapse-item>
                    </app-collapse>
                </b-card>
            </b-col>
        </b-row>
        <b-modal
            v-model="showModal"
            hide-footer
            title="Please verify to continue"
            @hide="hideModal"
        >
            <b-button
                variant="primary"
                block
                @click="clickVerify"
                :disabled="modalBtn"
                >Click to Verify</b-button
            >
        </b-modal>
        <!-- <vue-recaptcha
            class="m-0"
            ref="reCaptcha"
            :sitekey="sitekey"
            @verify="onVerify"
            @expired="onExpired"
            size="invisible"
        /> -->
        <vue-hcaptcha
            class="m-0"
            :sitekey="sitekey"
            size="invisible"
            ref="hCaptcha"
            @verify="onVerify"
            @expired="onExpired"
        />
    </div>
</template>

<script>
import {
    BCard,
    BCardBody,
    BAvatar,
    BCardText,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    BFormInput,
    BMedia,
    BImg,
    BSpinner,
    BFormGroup,
    BForm,
    BProgress,
    BOverlay,
    BModal,
    BCardTitle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "@validations";
import formatBytes from "../../mixins/format-bytes";
import { VueRecaptcha } from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

extend("max_links", (value) => {
    if (value.includes("\n")) {
        const links = value.split("\n");
        if (links.length <= 100) return true;
        return "Maximum is 100 links.";
    }
    return true;
});

export default {
    components: {
        BOverlay,
        BProgress,
        BFormGroup,
        BRow,
        BCol,
        BCardText,
        BFormTextarea,
        BButton,
        BFormInput,
        BMedia,
        BImg,
        BCard,
        BCardBody,
        BAvatar,
        BSpinner,
        AppCollapse,
        AppCollapseItem,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BModal,
        required,
        VueRecaptcha,
        BCardTitle,
        VueHcaptcha,
    },
    mixins: [formatBytes],
    directives: {
        Ripple,
    },

    data() {
        return {
            showLimits: false,
            modalBtn: false,
            sitekey: process.env.VUE_APP_HCAPTCHA,
            showModal: false,
            maxProgess: 0,
            valueProgess: 0,
            urllist: "",
            password: "",
            show: true,
            result: [],
            bw_limit: [],
            files_limit: [],
        };
    },
    created() {
        if (this.$store.getters.getUser.has_premium) {
            this.fetchLimits();
            this.fetchBandwidth();
        }
    },
    methods: {
        hideModal() {
            this.modalBtn = false;
        },
        onExpired() {
            this.$refs.hCaptcha.reset();
        },
        clickVerify() {
            this.modalBtn = true;
            this.$refs.hCaptcha.execute();
        },
        async onVerify(response) {
            await this.$store.dispatch("genVerify", response);
            this.onExpired();
            this.modalBtn = false;
            this.showModal = false;
        },
        fetchLimits() {
            this.$store
                .dispatch("currentLimitHost")
                .then((res) => {
                    this.bw_limit = res.data.data.filter((el) => el.bandwidth);
                    this.files_limit = res.data.data.filter((el) => el.files);
                })
                .catch((err) => console.log(err));
        },
        fetchBandwidth() {
            this.$store
                .dispatch("fetchBandwidth")
                .then((res) => {
                    this.maxProgess = res.data.bandwidth.maximum;
                    this.valueProgess = res.data.bandwidth.usage;
                })
                .catch((err) => console.log(err));
        },
        resetForm() {
            this.urllist = "";
            this.password = "";
            this.result = [];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        async onGenerate() {
            const links = [...new Set(this.urllist.split("\n"))];
            const { password } = this;
            this.resetForm();
            var verify = 0;
            for (let i = 0; i < links.length; i++) {
                let variant = "secondary";
                let message = links[i];
                if (!this.validURL(links[i])) {
                    variant = "danger";
                    message += " | URL not valid";
                }
                this.result.push({
                    variant,
                    message,
                });
            }
            for (let i = 0; i < links.length; i++) {
                if (verify == 1) return;
                if (this.result[i].variant != "danger") {
                    const data = {
                        url: links[i],
                        password,
                    };
                    await this.$store
                        .dispatch("generator", data)
                        .then((res) => {
                            if (!res.data.success) {
                                if (
                                    res.data.message ==
                                    "Please verify to continue."
                                ) {
                                    this.showModal = true;
                                    this.result.splice(i);
                                    this.urllist = links.splice(i).join("\n");
                                    verify = 1;
                                    return;
                                }
                                this.result[i].variant = "danger";
                                this.result[
                                    i
                                ].message += ` | ${res.data.message.toUpperCase()}`;
                            } else {
                                this.result[i].variant = "primary";
                                this.result[
                                    i
                                ].message = `<a class="text-white" target="_blank" href="${
                                    res.data.file.dllink
                                }">${res.data.file.name} | ${this.formatBytes(
                                    res.data.file.size
                                )}</a>`;
                                this.fetchLimits();
                                this.fetchBandwidth();
                            }
                        })
                        .catch((err) => console.log(err));
                }
            }
        },
        iconHost(data) {
            return `images/hoster/${data}.png`;
        },
        validURL(str) {
            const regex =
                /^(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

            if (!regex.test(str)) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style>
.inblock {
    width: 70%;
    display: inline-block;
}
.size {
    font-size: 12px;
}
.collapse-title {
    font-size: 1rem !important;
}
.progress {
    height: 1.5rem;
}

@media (max-width: 991px) {
    .right-side {
        max-width: 500px;
        right: -655px;
        padding-top: 25px;
        position: fixed;
        top: 131px;
        bottom: 0;
        overflow: auto;
        background: #f6f6f6;
        z-index: 200;
        transition: all 0.1s ease;
    }
    .right-side.active {
        right: 0;
        transition: all 0.25s ease;
    }
}
</style>
